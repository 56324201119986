<template>
    <el-scrollbar :height="(innerHeight-170)+' px'">
        <el-row v-for="row,rowk in tree" justify="space-between" :gutter="12"
            :style="'width:'+(screenWidth - 16)+'px ;'">
            <el-col v-for="col,colk in row" :span="screenWidth <= 1200 ? 24 : col.span " v-loading="col.loading">
                <el-card v-if="col.types == 'number'" class="br_con">
                    <el-statistic :title="col.option.title" :value="col.option.value" />
                </el-card>
                <el-card v-else-if="col.types == 'echarts'">
                    <el-row>
                        <el-tooltip content="刷新数据" placement="bottom" effect="light">
                            <el-button size="small" icon="Refresh" circle @click="updata_axis(col.id,rowk,colk)" />
                        </el-tooltip>
                        <CatSearch :searchHead="tableHead" @search="updata_axis(col.id,rowk,colk)"
                            :searchForm='col.searchs' v-if="col.search" />
                    </el-row>
                    <el-row v-if="col.cad && screenWidth <= 1200">
                        <el-scrollbar>
                            <div style="display: flex;">
                                <el-card shadow="hover" v-for="col in col.cad" class="scrollbar-demo-item">
                                    <h4>{{col.title}}</h4>
                                    <el-tooltip :content="col.value.toString()" placement="bottom" effect="light">
                                        <h1>{{viewCount(col.value)}}</h1>
                                    </el-tooltip>
                                </el-card>
                            </div>
                        </el-scrollbar>
                    </el-row>
                    <el-row>
                        <el-col :span="4" v-if='col.cad && screenWidth > 1200'>
                            <el-scrollbar :height="col.height?col.height:'350px'">
                                <el-card shadow="hover" v-for="col in col.cad" class="scrollbar-demo-item">
                                    <h4>{{col.title}}</h4>
                                    <el-tooltip :content="col.value.toString()" placement="bottom" effect="light">
                                        <h1>{{viewCount(col.value)}}</h1>
                                    </el-tooltip>
                                </el-card>
                            </el-scrollbar>
                        </el-col>
                        <el-col :span="col.cad?20:24">
                            <div :id="'chart'+col.id" :style="{height: col.height ? col.height : '350px'}"></div>
                        </el-col>
                    </el-row>
                </el-card>
                <el-card v-else-if="col.types == 'table'">
                    <el-row v-if="col.cad && screenWidth <= 1200">
                        <el-scrollbar>
                            <div style="display: flex;">
                                <el-card shadow="hover" v-for="col in col.cad" class="scrollbar-demo-item">
                                    <h4>{{col.title}}</h4>
                                    <el-tooltip :content="col.value.toString()" placement="bottom" effect="light">
                                        <h1>{{viewCount(col.value)}}</h1>
                                    </el-tooltip>
                                </el-card>
                            </div>
                        </el-scrollbar>
                    </el-row>
                    <el-row>
                        <el-col :span="4" v-if='col.cad && screenWidth > 1200'>
                            <el-scrollbar :height="col.height?col.height:'350px'">
                                <el-card shadow="hover" v-for="col in col.cad" class="scrollbar-demo-item">
                                    <h4>{{col.title}}</h4>
                                    <el-tooltip :content="col.value.toString()" placement="bottom" effect="light">
                                        <h1>{{viewCount(col.value)}}</h1>
                                    </el-tooltip>
                                </el-card>
                            </el-scrollbar>
                        </el-col>
                        <el-col :span="20">
                            <h1>{{col.hedname}}</h1>
                            <el-table :data="col.tableData" :height="col.height?col.height:'370px'" style="width: 100%"
                                show-overflow-tooltip>
                                <el-table-column v-for="hid in col.hid" :prop="hid.prop" :label="hid.label" />
                            </el-table>
                        </el-col>
                    </el-row>
                </el-card>
                <el-card v-else>
                    <el-empty description="待开发" />
                </el-card>
            </el-col>
        </el-row>
    </el-scrollbar>
</template>

<script>
    import * as echarts from "echarts";
    import axios from "@/utils/request";
    import CatSearch from '@/components/CatSearch.vue'
    import {
        mapGetters
    } from 'vuex'
    export default {
        name: 'Briefing',
        components: {
            CatSearch
        },
        computed: mapGetters(['screenWidth', 'innerHeight']),
        mounted() {

        },
        created() {
            axios({
                method: "post",
                url: 'getheader',
                data: {
                    tabname: '数据简报头'
                },
            }).then(res => {
                this.tableHead = res.tableHead
                axios({
                    method: "post",
                    url: 'briefing',
                    data: this.lookup,
                }).then(res => {
                    this.tree = res.data
                    this.getdatas()
                })
            })
        },
        data() {
            return {
                tree: [],
                loading: false,
                tableHead: [],
                fileList: [],
                counterpoise_search: 1,
                lookup: {
                    tabname: 0,
                    id: 0,
                    search: {},
                    export: false,
                },
            }
        },
        methods: {
            //获取数据
            getdatas(v = this.lookup) {
                this.tree.forEach((row, rowk) => {
                    row.forEach((col, colk) => {
                        if (col.types == 'empty') {
                            // 跳过空对象
                            return;
                        }
                        this.lookup.id = col.id
                        this.lookup.search = col.searchs
                        axios({
                            method: "post",
                            url: 'briefing',
                            data: this.lookup,
                        }).then(res => {
                            if (col.types == 'number') {
                                this.tree[rowk][colk]['option'] = res.data
                            }
                            if (col.types == 'table') {
                                this.tree[rowk][colk]['cad'] = res.data.cad
                                this.tree[rowk][colk]['tableData'] = res.data.tableData
                                this.tree[rowk][colk]['hid'] = res.data.hid
                                this.tree[rowk][colk]['hedname'] = res.data.hedname
                            }
                            if (col.types == 'echarts') {
                                if (res.data.cad) {
                                    this.tree[rowk][colk]['cad'] = res.data.cad
                                }
                                this.create_axis('chart' + col.id, res.data)
                            }
                            this.tree[rowk][colk]['loading'] = false
                        })
                    })
                })
            },
            updata_axis(id, rowk, colk) {
                this.lookup.id = id
                this.tree[rowk][colk]['loading'] = true
                this.lookup.search = this.tree[rowk][colk]['searchs']
                axios({
                    method: "post",
                    url: 'briefing',
                    data: this.lookup,
                }).then(res => {
                    if (res.data.cad) {
                        this.tree[rowk][colk]['cad'] = res.data.cad
                    }
                    this.create_axis('chart' + id, res.data)
                    this.tree[rowk][colk]['loading'] = false
                })
            },
            create_axis(id, option) {
                let re = document.getElementById(id)
                let myChart = echarts.getInstanceByDom(re)
                if (myChart == undefined) {
                    myChart = echarts.init(re);
                }
                myChart.setOption(option)
                window.addEventListener('resize', () => {
                    myChart.resize();
                }, false)
            },
            viewCount(num) {
                //单位转化为k（千）或 w（万）,并截取保留一位小数，不进行四舍五入 
                // 1e3为10的三次幂=1000 1e4=10000
                if (typeof num === "string") {
                    return num
                }
                if (num >= 1e3 && num < 1e4) {
                    return Math.floor(num / 1e2) / 10 + 'K'
                } else if (num >= 1e4) {
                    return Math.floor(num / 1e3) / 10 + 'w'
                } else {
                    return num
                }
            }
        }
    }
</script>

<style scoped>
    .br_con {
        /* padding: 16px; */
        text-align: center;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .el-row {
        margin-bottom: 8px;
    }

    .el-col:last-child {
        margin-bottom: 0;
    }

    .el-col {
        padding-right: 3px !important;
        padding-left: 3px !important;
        border-radius: 1.5px;
    }

    .scrollbar-demo-item {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 4px;
    }
</style>